import { Injectable } from '@angular/core';
import { GetEficienciaRecorridosResponse } from '../api/charts/eficiencia-recorridos';
import { GetEficienciaKmResponse } from '../api/charts/eficiencia-km';
import { GetAllRecordsByFilterNodeResponse } from '../api/deliveries/recordsbyfilter';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Tour } from '../models/tour.model';


@Injectable({
    providedIn: 'root'
})
export class TourGraphicService {
    private graphicEfficiencyTime = 'efficiecy-time';
    private graphicEfficiencyKilometer = 'efficieny-kilometer';
    private dataTours = 'list-tours';
    private kpirouteCompleted = 'route-complete';
    private kpiroutePending = 'route-pending';
    private kpipackageCompleted = 'package-complete';
    private kpipackagePending = 'package-pending';

    private api_gugu = `${environment.api_gugu}`;

    constructor(private http: HttpClient) { }


    // Save the main data array
    saveDatagraphicEfficiencyTime(data: GetEficienciaRecorridosResponse): void {
        localStorage.setItem(this.graphicEfficiencyTime, JSON.stringify(data));
    }

    // Retrieve the main data array
    getDatagraphicEfficiencyTime(): GetEficienciaRecorridosResponse {
        const data = localStorage.getItem(this.graphicEfficiencyTime);
        return data ? JSON.parse(data) : null;
    }

    // Clear the main data on logout
    clearDataOnLogout(): void {
        localStorage.removeItem(this.graphicEfficiencyTime);
        this.clearAllKpis(); // Also clear all KPI data
    }



    // Save the main data array
    saveDatagraphicEfficiencyKilometer(data: GetEficienciaKmResponse): void {
        localStorage.setItem(this.graphicEfficiencyKilometer, JSON.stringify(data));
    }

    // Retrieve the main data array
    getDatagraphicEfficiencyKilometer(): GetEficienciaKmResponse {
        const data = localStorage.getItem(this.graphicEfficiencyKilometer);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogoutgraphicEfficiencyKilometer(): void {
        localStorage.removeItem(this.graphicEfficiencyKilometer);
        this.clearAllKpis(); // Also clear all KPI data
    }



    saveDataTour(data: GetAllRecordsByFilterNodeResponse): void {
        localStorage.setItem(this.dataTours, JSON.stringify(data));
    }

    // Retrieve the main data array
    getDataTour(): GetAllRecordsByFilterNodeResponse {
        const data = localStorage.getItem(this.dataTours);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataTour(): void {
        localStorage.removeItem(this.dataTours);
        this.clearAllKpis(); // Also clear all KPI data
    }

    // Save KPI data
    saveKpi(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieve KPI data
    getKpi(key: string): any {
        const kpi = localStorage.getItem(key);
        return kpi ? JSON.parse(kpi) : null;
    }

    // Clear KPI data
    clearKpi(key: string): void {
        localStorage.removeItem(key);
    }

    // Save specific KPI data
    savekpirouteCompleted(value: any): void {
        this.saveKpi(this.kpirouteCompleted, value);
    }

    savekpiroutePending(value: any): void {
        this.saveKpi(this.kpiroutePending, value);
    }

    savekpipackageCompleted(value: any): void {
        this.saveKpi(this.kpipackageCompleted, value);
    }

    savekpipackagePending(value: any): void {
        this.saveKpi(this.kpipackagePending, value);
    }

    // Retrieve specific KPI data
    getkpirouteCompleted(): any {
        return this.getKpi(this.kpirouteCompleted);
    }

    getkpiroutePending(): any {
        return this.getKpi(this.kpiroutePending);
    }

    getkpipackageCompleted(): any {
        return this.getKpi(this.kpipackageCompleted);
    }

    getkpipackagePending(): any {
        return this.getKpi(this.kpipackagePending);
    }

    // Clear all KPI data
    clearAllKpis(): void {
        this.clearKpi(this.kpipackageCompleted);
        this.clearKpi(this.kpipackagePending);
        this.clearKpi(this.kpirouteCompleted);
        this.clearKpi(this.kpiroutePending);
    }


    // ---- Obtener todas las ubicaciones de los vehiculos ---

    // Se esta convirtiendo en una promesa, mediante el fistfirstValueFrom
    async getUserCoordinates(): Promise<any> {
        return await firstValueFrom(
            this.http.get(this.api_gugu + '/app-mobile-routes/get_user_coordinates')
        );
    }


    async getNearestDrivers(latitude: number, longitude: number): Promise<any> {
        const params = {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
        };

        return await firstValueFrom(
            this.http.get(`${this.api_gugu}/app-mobile-routes/get_nearest_drivers`, { params })
        );
    }

    // Nuevo método POST para crear una ruta con paquetes
    async createRoutePackage(tour: Tour): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return await firstValueFrom(
            this.http.post(`${this.api_gugu}/app-mobile-routes/create_route_package`, tour, { headers })
        );
    }


    // Se listan los tours
    getListTours(startDate: Date, finDate: Date, page: number = 1, pageSize: number = 5): Observable<any> {
        // Formatear las fechas a 'yyyy-MM-dd'
        const formattedStartDate = this.formatDate(startDate);
        const formattedFinDate = this.formatDate(finDate);
      
        // Crear los parámetros de la URL para la paginación
        const params = new HttpParams()
          .set('page', page.toString())
          .set('pageSize', pageSize.toString());
      
        // Construir la URL completa con las fechas formateadas
        const url = `${this.api_gugu}/tours/getListTours/${formattedStartDate}/${formattedFinDate}`;
      
        // Realizar la solicitud GET con los parámetros
        return this.http.get(url, { params }).pipe(
          map((response: any) => {
            // Estructurar la respuesta para incluir los tours y la paginación
            const tours = response.data.data; // Lista de tours
            const pagination = response.data.pagination; // Información de la paginación
      
            return {
              tours, // Los tours obtenidos
              pagination: {
                totalRecords: pagination.totalRecords, // Total de registros
                totalPages: pagination.totalPages, // Total de páginas
                currentPage: parseInt(pagination.currentPage, 10), // Página actual
                pageSize: parseInt(pagination.pageSize, 10) // Tamaño de página
              }
            };
          })
        );
      }
      
    

    // Método que formate las fechas
    private formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }



    async getRouteById(id: string): Promise<any> {
        const url = `${this.api_gugu}/app-mobile-routes/get_route?route_id=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }


    // --------------------- KPIS ----------------
    // Rutas Finalizadas
    async getKPIRoutesFinish(startDate: Date, finDate: Date): Promise<any> {
        const formattedStartDate = this.formatDate(startDate);
        const formattedFinDate = this.formatDate(finDate);

        const url = `${this.api_gugu}/tours/getKpiRoutesFinished/${formattedStartDate}/${formattedFinDate}`;


        // Realizar la solicitud GET
        return await firstValueFrom(
            this.http.get(url)
        );
    }

    // Rutas Pendientes
    async getKPIRoutesPending(startDate: Date, finDate: Date): Promise<any> {
        const formattedStartDate = this.formatDate(startDate);
        const formattedFinDate = this.formatDate(finDate);

        const url = `${this.api_gugu}/tours/getKpiRoutesPending/${formattedStartDate}/${formattedFinDate}`;

        return await firstValueFrom(
            this.http.get(url)
        );
    }

    // Paquetes Entregados
    async getKPIPackagesDelivered(startDate: Date, finDate: Date): Promise<any> {
        const formattedStartDate = this.formatDate(startDate);
        const formattedFinDate = this.formatDate(finDate);

        const url = `${this.api_gugu}/tours/getKpiPackageFinished/${formattedStartDate}/${formattedFinDate}`;

        return await firstValueFrom(
            this.http.get(url)
        );
    }

    // Paquetes No Entregados
    async getKPIPackagesUnDelivered(startDate: Date, finDate: Date): Promise<any> {
        const formattedStartDate = this.formatDate(startDate);
        const formattedFinDate = this.formatDate(finDate);

        const url = `${this.api_gugu}/tours/getKpiPackagePending/${formattedStartDate}/${formattedFinDate}`;

        return await firstValueFrom(
            this.http.get(url)
        );
    }
 
    async getDriverById(id: string): Promise<any> {
        const url = `${this.api_gugu}/app-mobile-routes/get_info_id?driver_id=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }

    
    async getHistorialOdometer(id:number):Promise<any>{
        const url = `${this.api_gugu}/app-mobile-routes/getHistorialOdometerByVehicleId?vehicleId=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }

    async getOdometer(id:number):Promise<any>{
        const url = `${this.api_gugu}/app-mobile-routes/getOdometerByVehicleId?vehicleId=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }

    async getHistorialMaintenances(id:number):Promise<any>{
        const url = `${this.api_gugu}/app-mobile-routes/getHistoryMaintenancesByVehicleId?vehicleId=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }

    async getHistorialRoutes(id:number):Promise<any>{
        const url = `${this.api_gugu}/app-mobile-routes/get_route_finished?vehicle_id=${id}`;
        return await firstValueFrom(
            this.http.get(url)
        );
    }




}
