// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { api } from "src/app/api/api";

export const environment = {
  production: true,
  API_URL: import.meta.env.NG_APP_API_URL,
  GOOGLE_MAPS_KEY: import.meta.env.NG_APP_GOOGLE_MAPS_KEY ,
  api_gugu:'https://gugu.fleets.backend.chvconsulting.es',
  //api_gugu:'http://localhost:7085',
  //Odoo
  database:'odoo-dev',
  user:'2',
  password:'GuguCH4Consulting',
  url_odoo:'https://erp.dev.chvconsulting.es/jsonrpc/',
  //url_odoo:'http://localhost:8069/jsonrpc/',
  
  //Ant
  api_ant:'https://ant-kurier.backend.chvconsulting.es/',
  mapbox_token: 'pk.eyJ1Ijoic2hhbmtzNSIsImEiOiJjbHRxdGdybWMwYjhzMnFvZXlsZWxwMjVqIn0.Zxt7C06ZJhhRa3kRE3zcow',
  GOOGLE_MAP_KEY:'AIzaSyA6uz8JB9L4GuRW24PhqrSTprzcTbEKAI8',
  


  //api_local:'http://localhost:3000/'

  //Drivers
  departmentId: '3',
  address_id : '1'
    
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
