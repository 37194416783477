<div class="flex items-center justify-between gap-x-6">
    <!-- Campo para seleccionar el rango de fechas -->
    <div class="rounded-[20px] bg-white shadow-[0px_4px_8px_0px_rgba(171,_190,_209,_0.40)] px-4 text-[#8E8EA9] text-sm ml-auto flex items-center overflow-hidden">
      <app-feather-icons icon="calendar" class="mr-4"></app-feather-icons>
      <input
        type="text"
        ngxDaterangepickerMd
        [(ngModel)]="selectedRangeDate"
        (ngModelChange)="onDateRangeChange($event)"
        [locale]="localeConfig"
        class="py-[10px] flex-1 outline-none w-[160px]"
        placeholder="{{ selectedRangeDate.startDate.format('YYYY-MM-DD') }} - {{ selectedRangeDate.endDate.format('YYYY-MM-DD') }}"
        aria-label="Select a date range"
      />
    </div>
  
    <!-- Selector de períodos -->
    <select [(ngModel)]="selectedPeriod" (ngModelChange)="onPeriodChange($event)"
            class="rounded-[20px] bg-white h-10 px-4 shadow-[0px_4px_8px_0px_rgba(171,_190,_209,_0.40)] text-[#8E8EA9] text-sm overflow-hidden">
      <option value="vacio" disabled [ngClass]="{'text-gray-400': selectedPeriod === 'vacio'}">
        {{ 'Select a period' | translate }}
      </option>
      <option *ngFor="let period of periods" [value]="period.value" [disabled]="period.disabled"
              [ngClass]="{ 'text-black': !period.disabled, 'text-gray-400': period.disabled }">
        {{ period.label | translate }}
      </option>
    </select>
  
    <!-- Botón de aplicar filtros -->
    <button (click)="onApplyFilters()" [disabled]="!selectedPeriod || selectedPeriod === 'vacio'" class="btn btn-md btn-outline-primary">
      Aplicar
    </button>
  </div>
  