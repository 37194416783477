import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs/esm';
import { PeriodValueES } from '../../../utils/period';
import { TranslateService } from '@ngx-translate/core';

export interface Period {
  value: string;
  label: string;
  disabled: boolean;
}


@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit {
  
  @Input() periods: Period[] = [];  // Lista de períodos
  @Input() selectedPeriod: PeriodValueES = 'vacio';  // Período seleccionado
  @Input() selectedRangeDate: { startDate: Dayjs; endDate: Dayjs } = { 
    startDate: dayjs().subtract(1, 'day'),
    endDate: dayjs()
  };

  @Output() selectedChange = new EventEmitter<any>();  // Emite cambios de selected
  @Output() selectedPeriodChange = new EventEmitter<PeriodValueES>();  // Emite el cambio del período
  @Output() selectedRangeDateChange = new EventEmitter<{ startDate: Dayjs; endDate: Dayjs }>();  // Emite el cambio de rango de fechas

  // Configuración de la fecha
  // localeConfig = {
  //   format: 'YYYY-MM-DD',  // Formato de fecha
  //   locale: 'en',  // Idioma (en este caso inglés)
  // };
  localeConfig: any; 
  initializeLocaleConfig() {
    this.localeConfig = {
      format: 'YYYY-MM-DD',
      displayFormat: 'YYYY-MM-DD'
    };

    const dynamicLocaleConfig = this.translateService.instant('daterangepicker.locale');
    this.localeConfig = { ...this.localeConfig, ...dynamicLocaleConfig };
  }


  // Lógica para habilitar o deshabilitar los períodos según las fechas
  onDateRangeChange(event: { startDate: Dayjs; endDate: Dayjs }) {
    const periodMap = {
      daily: { min: 1, max: 7 },
      weekly: { min: 7, max: 30 },
      monthly: { min: 28, max: 365 },
      quarterly: { min: 90, max: 365 },
      quinmester: { min: 180, max: 365 },
      yearly: { min: 364, max: 100_000 }
    };

    // Calcular la diferencia en días entre las fechas
    const diffInDays = event.endDate.diff(event.startDate, 'day');

    // Actualizar la lista de períodos según la diferencia de días
    this.periods = this.periods.map(period => ({
      ...period,
      disabled: !this.isPeriodEnabled(period.value as PeriodValueES, diffInDays, periodMap)
    }));

    // Emitir el cambio de rango de fechas
    this.selectedRangeDateChange.emit(event);
  }

  // Verificar si el período está habilitado
  isPeriodEnabled(periodValue: PeriodValueES, diffInDays: number, periodMap: any): boolean {
    const period = periodMap[periodValue];
    if (!period) return false; // Si no existe el período, deshabilitarlo.
    return diffInDays >= period.min && diffInDays <= period.max;
  }

  // Manejar el cambio de período
  onPeriodChange(value: PeriodValueES) {
    this.selectedPeriod = value;
    this.selectedPeriodChange.emit(value);
  }

  constructor(
    protected translateService: TranslateService,
  ){ }

  // Método para aplicar filtros (ejemplo de lo que se hace con los valores seleccionados)
  onApplyFilters() {
    console.log('Fecha de inicio:', this.selectedRangeDate.startDate.format('YYYY-MM-DD'));
    console.log('Fecha de fin:', this.selectedRangeDate.endDate.format('YYYY-MM-DD'));
    console.log('Período seleccionado:', this.selectedPeriod);

    // Emite los valores para que el componente padre pueda manejar los filtros aplicados
    this.selectedChange.emit({
      range: this.selectedRangeDate,
      period: this.selectedPeriod
    });
  }

  ngOnInit() {
    this.initializeLocaleConfig();

    if (this.periods.length === 0) {
      this.periods = [
        { value: 'daily', label: 'Daily', disabled: false },
        { value: 'weekly', label: 'Weekly', disabled: false },
        { value: 'monthly', label: 'Monthly', disabled: false },
        { value: 'quarterly', label: 'Quarterly', disabled: false },
        { value: 'quinmester', label: 'Quinmester', disabled: false },
        { value: 'yearly', label: 'Yearly', disabled: false }
      ];
    }

    console.log('Períodos inicializados:', this.periods);
  }
}
